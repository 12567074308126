"use strict";

const swprBreakpoint = window.matchMedia("(min-width:1024px)");

let myProjectSwiper;

const breakpointChecker = function() {
  // if larger viewport and multi-row layout needed
  if (swprBreakpoint.matches === true) {
    // clean up old instances and inline styles when available
    if (myProjectSwiper !== undefined) myProjectSwiper.destroy(true, true);
    // or/and do nothing
    return;
    // else if a small viewport and single column layout needed
  } else if (swprBreakpoint.matches === false) {
    // fire small viewport version of swiper
    return enableSwiper();
  }
};

const enableSwiper = function() {
  myProjectSwiper = new Swiper(
    ".section--projectsqueue .swiper-container-projects",
    {
      speed: 400,
      grabCursor: true,
      roundLengths: true,
      direction: "horizontal",
      updateOnWindowResize: true,
      centeredSlides: false,
      loop: false,
      breakpoints: {
        320: {
          //loop: false,
          slidesPerView: 1.25,
          //centeredSlides: false,
          spaceBetween: 20
        },
        480: {
          //loop: false,
          slidesPerView: 1.55,
          //centeredSlides: false,
          spaceBetween: 30
        },
        768: {
          //loop: false,
          slidesPerView: 2.55,
          //centeredSlides: false,
          spaceBetween: 40
        }
      }
    }
  );

  //const transBG = document.querySelector(".projectsqueue__item");

  myProjectSwiper.on("slideChange", function() {});
};

swprBreakpoint.addListener(breakpointChecker);

breakpointChecker();
