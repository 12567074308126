"use strict";
window.onload = function() {
  const notification = document.querySelector(".notification");
  const accept_btn = notification.getElementsByClassName("button-is-oke")[0];
  const deny_btn = notification.getElementsByClassName("button-is-not-oke")[0];
  const ua_code = "UA-156435615-1"; // Our analytics account ID
  const cookie_str = `ga-disable-${ua_code}`; // The cookie used by analytics to identify whether they should track or not. (true = dont track, false = track)

  let has_class = function has_class(element, class_name) {
    return (" " + element.className + " ").indexOf(" " + class_name + " ") > -1;
  };

  let show_cookies = function show_cookies() {
    if (!has_class(notification, "is-active"))
      notification.classList += "is-active";
  };

  let hide_cookies = function hideCookies() {
    if (has_class(notification, "is-active"))
      notification.className = notification.className.replace(
        /\is-active\b/g,
        ""
      );
  };

  deny_btn.addEventListener("click", function() {
    document.cookie = `${cookie_str}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    window[cookie_str] = true;

    hide_cookies();
  });

  accept_btn.addEventListener("click", function() {
    document.cookie = `${cookie_str}=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    window[cookie_str] = false;

    hide_cookies();
  });

  if (document.cookie.indexOf(`${cookie_str}=true`) > -1) {
    window[cookie_str] = true;
  } else if (document.cookie.indexOf(`${cookie_str}=false`) > -1) {
    window[cookie_str] = false;
  } else {
    show_cookies();
  }

  document.getElementsByClassName("mainmenu").className = "is-loaded";

  document.addEventListener(
    "touchstart",
    function addtouchclass(e) {
      document.documentElement.classList.add("can-touch");

      document.removeEventListener("touchstart", addtouchclass, false);
    },
    false
  );
  let screenWidth = document.body.clientWidth;

  let onresize = function(e) {
    if (screenWidth >= 1023) {
      document.body.classList.remove("mobile-nav");
      document.getElementById("header-hamburger").classList.remove("is-active");
    }
  };
  window.addEventListener("resize", onresize);
};

let no_js = document.getElementsByClassName("no-js");

let header = document.getElementById("mainmenu");

let sticky = header.offsetTop;

function MainMenuFixed() {
  if (window.pageYOffset > sticky) {
    header.classList.add("mainmenu--is-fixed");
  } else {
    header.classList.remove("mainmenu--is-fixed");
  }
}

//window.onscroll = function() {
//MainMenuFixed();
//};

let last_known_scroll_position = 0;
let ticking = false;

window.addEventListener("scroll", function(e) {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      MainMenuFixed(last_known_scroll_position);
      ticking = false;
    });

    ticking = true;
  }
});

if (no_js.length) {
  for (let i = 0; i < no_js.length; i++) {
    no_js[i].classList.remove("no-js");
  }
}

let coll = document.getElementsByClassName("collapsible");

let i;

for (i = 0; i < coll.length; i++) {
  coll[0].classList.add("is-open");
  let defaultOpen = coll[0].nextElementSibling;

  defaultOpen.style.maxHeight = "500px";

  coll[i].addEventListener("click", function() {
    this.classList.toggle("is-open");
    let content = this.nextElementSibling;

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  });
}

(function() {
  var lastTime = 0;
  var vendors = ["ms", "moz", "webkit", "o"];
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + "RequestAnimationFrame"];
    window.cancelAnimationFrame =
      window[vendors[x] + "CancelAnimationFrame"] ||
      window[vendors[x] + "CancelRequestAnimationFrame"];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function(callback, element) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function() {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function(id) {
      clearTimeout(id);
    };
})();

// Smooth scrolling for browsers that don't support CSS smooth scrolling

if (
  window.getComputedStyle(document.documentElement).scrollBehavior !== "smooth"
) {
  document.querySelectorAll('[href^="#"]').forEach(internalLink => {
    const targetElement = document.querySelector(
      internalLink.getAttribute("href")
    );
    if (targetElement) {
      internalLink.addEventListener("click", e => {
        targetElement.scrollIntoView({
          behavior: "smooth"
        });
        e.preventDefault();
      });
    }
  });
}

var menu_clicked = false;

var hamburger = document.getElementById("header-hamburger");
if (hamburger) {
  hamburger.onclick = function(button) {
    var target = button.target || button.srcElement;
    if (!target.classList.contains("js-header-hamburger")) {
      target = target.parentElement;
      if (!target.classList.contains("js-header-hamburger")) {
        return false;
      }
    }
    var header = document.getElementById("mainmenu");
    if (!header) {
      return false;
    }
    if (target.classList.contains("is-active")) {
      remove_is_active_and_scroll();
    } else {
      remove_is_active_and_scroll();
      target.className += " is-active";
      header.className += " is-active";
      document.body.className += "mobile-nav";
    }
  };
}

function remove_is_active_and_scroll() {
  var is_active_elements = document.getElementsByClassName(" is-active");
  var i;
  var length = is_active_elements.length;
  for (i = 0; i < length; i++) {
    is_active_elements[0].classList.remove("is-active");
  }
  if (document.body.classList.contains("mobile-nav")) {
    document.body.classList.remove("mobile-nav");
  }
}

document.querySelectorAll(".mainmenu__link").forEach(internalLink => {
  const closeElement = document.querySelector(
    internalLink.getAttribute("href")
  );
  if (closeElement) {
    internalLink.addEventListener("click", e => {
      document.body.classList.remove("mobile-nav");
      document.getElementById("header-hamburger").classList.remove("is-active");
    });
  }
});

const modal = document.getElementById("cookie-modal");
const open = document.getElementById("open-cookies");
const close = document.getElementById("close");

dialogPolyfill.registerDialog(modal);

open.addEventListener("click", function () {
  modal.showModal();
});

close.addEventListener("click", function () {
  modal.close("cancelled");
});

modal.addEventListener("click", function (event) {
  if (event.target === modal) {
    modal.close("cancelled");
  }
});
